/* App.css */
.orchestrator-api {
  font-size: 28px; /* Adjust the font size as needed */
  font-weight: bold; /* Optional: make the text bold */
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1b1b1b;
  color: #fff;
  padding: 1px;
  height: 40px;
  position: fixed;
  top: 0;  /* Ensure the top bar is at the top */
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.sidebar {
  background-color: #141414;
  color: #fff;
  width: 250px;
  min-height: calc(100vh - 40px); /* Adjust for the top bar height */
  position: fixed;
  top: 40px; /* Adjust the top margin to match the height of the top bar */
  left: 0;
  transition: width 0.3s;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.collapsed {
  width: 50px;
}

.main-content {
  margin-top: 30px; /* Adjust to prevent overlapping with the top bar */
  background-color: #1b1b1b;
  color: #fff;
  min-height: calc(100vh - 70px); /* Ensure it takes the full height minus the top bar */
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Align items to the top */
  padding: 20px; /* Add padding for better spacing */
}

.main-content.collapsed {
  margin-left: 50px; /* Adjust the margin to match the collapsed sidebar width */
  width: calc(100% - 50px); /* Ensure it takes the full width available besides the collapsed sidebar */
}

.top-bar-title {
  display: flex;
  width: 100%;
}

.App-logo {
  height: 40px;
  animation: App-logo-spin infinite 20s linear;
  margin-right: 10px; /* Space between the logo and title */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #1b1b1b;
}

.sidebar-logo img {
  height: 40px;
  animation: App-logo-spin infinite 20s linear;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-content.collapsed {
  display: none;
}

.sidebar-content ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-content li {
  padding: 10px 0;
}

.sidebar-content li a {
  color: #fff;
  text-decoration: none;
}

/* Adjusting the form container */
.config-form-container {
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
}

.config-form {
  background-color: #2c2f33;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  width: 100%;
  max-width: 600px;
  color: #fff;
  font-family: 'Arial', sans-serif;
  margin-bottom: 20px; /* Added space between form and response */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.config-form label {
  display: block;
  margin-bottom: 8px; /* More space between label and input */
  font-size: 14px; /* Larger font size */
  color: #61dafb; /* Light blue color */
  text-align: left;
  width: 100%; /* Ensure labels take the full width */
  max-width: 600px;
}

.config-form input {
  width: 110%;
  max-width: 600px;
  padding: 12px; /* Increased padding for better usability */
  margin-bottom: 20px; /* More space between inputs */
  border: 1px solid #3a3a3a; /* Subtle border */
  border-radius: 5px;
  background-color: #1b1b1b;
  color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transition for focus state */
}

.config-form input:focus {
  border-color: #61dafb;
  box-shadow: 0 0 8px rgba(97, 218, 251, 0.5);
  outline: none;
}

.config-form button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Transition for hover state */
}

.config-form button:hover {
  background-color: #52c7ea;
}
/* Centering the form title */
.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  width: 111%;
}
.response-container {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #2c2f33; /* Match the form background */
  border-radius: 4px;
  color: #fff;
  width: 105%;
  max-width: 800px; /* Set a maximum width for the response */
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.response-message {
  font-size: 20px;
  font-weight: bold;
  color: #4caf50; /* Green color for success */
  margin-bottom: 20px;
}

.response-content {
  font-family: monospace;
  white-space: pre-wrap; /* To preserve formatting */
  color: #ddd;
  background-color: #1b1b1b; /* Dark background to match the form */
  padding: 30px;
  border-radius: 4px;
  overflow-x: auto; /* Add horizontal scroll if needed */
}

/* Centering the form title */
.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}
.custom-link {
  color: white;
}