/* Sidebar.css */

.sidebar {
  background-color: #141414;
  color: #fff;
  width: 250px;
  min-height: calc(100vh - 0px); /* Adjust for the top bar height */
  position: fixed;
  top: 20px; /* Adjust the top margin to match the height of the top bar */
  left: 0;
  transition: width 0.3s;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #1b1b1b;
}

.sidebar-logo img {
  height: 40px;
  animation: App-logo-spin infinite 20s linear;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-content.collapsed {
  display: none;
}

.sidebar-content ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-content li {
  padding: 10px 0;
}

.sidebar-content li a {
  color: #fff;
  text-decoration: none;
}

.collapsed-logo {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  display: inline-block;
  fill: #61dafb; /* Change this to your desired color */
}
